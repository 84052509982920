var Cameraland = Cameraland || {};

Cameraland.ScrollTo = class {
    constructor(selector) {
        $j(selector).on('click', this.onClick.bind(this));
    }

    onClick(event) {
        event.preventDefault();

        const currentTarget = $j(event.currentTarget);
        const scrollToSelector = currentTarget.attr('href') || currentTarget.data('scroll-to');
        const scrollOffset = currentTarget.data('scroll-offset') || 70;


        $j('html, body').animate({
            scrollTop: $j(scrollToSelector).offset().top - scrollOffset
        }, 300);
        return true;
    }
};

$j(function () {
    new Cameraland.ScrollTo('.scroll-to');
});